.myTabBarPage{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    .myTabBar{
        background-color:#FFFFFF;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .tarBarItem{
            width: 25%;
            display: flex;
            flex-direction:column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            .tarBarText{
                // height:34px;
                margin-top: 5px;
                text-align:center;
                // width: 48px;
            }
        }
    }
}