.imgList{
    display: flex;
    align-items: flex-start;;
    max-width: 100%;
    overflow-x: auto; 
    // margin-top: 10px;
    .imgListItem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        width: 120px;
        img{
            width: 120px;
            height: 90px;
            object-fit: cover;
        }
    }
    
}
.imgList > :last-child{
    margin-right: 0px;
}
.imgList::-webkit-scrollbar {
    display: none; /* 隐藏滚动条（适用于Chrome和Safari） */
}
.imgList {
    -ms-overflow-style: none; /* 隐藏IE和Edge中的滚动条 */
    scrollbar-width: none; /* 隐藏Firefox中的滚动条 */
}
    
