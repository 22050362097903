.bgImg{
  background-image: url('../../../assets/bg-top.svg');
  background-size: 100% auto; 
  background-position: center; 
  width: 100%;
  height: calc(100vw / 5); /* 高度是宽度的 1/5 */
  // padding-top: 20%; 
  z-index: -100;
  color: #3D4142; 
  display:flex;
  align-items:flex-end;
  img{
    margin-left:20px;
    margin-bottom:5px;
    margin-right: auto;
    width: 117px;
  }
  .btnBox,.changeLg{
    line-height: 40px;
    align-items: center;
    margin-right:20px;
  }
  .btnBox,.changeLg{
    > * {
      margin-left: 5px;
      color: #3D4142;
      font-size: 16px;
      font-weight: 500;
      border: none;
      cursor: pointer;
    }
  }
  // button{
  //   font-size: 16px;
  //   font-weight: 500;
  //   // background-color: transparent; /* 设置为透明背景 */
  //   color: #3D4142;
  //   border: none;
  //   cursor: pointer;
  // }
}
