.MainContent >div {
    background-color: #fff;
    margin-top: 10px;
}
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow: auto; /* 保留滚动功能 */
  }
  
/* 隐藏滚动条 */
