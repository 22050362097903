#imgFrame{
  margin-top: 0px;
  .imgtxt{
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div{
      width: 100%;
      line-height: 20px;
      font-size: 20px;
      text-align: center;
      color: #003946;
    }
    .productName{
      font-size: 14px;
    }
  }
  .imgItem{
    width: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
  }
  .bg2{
    margin-top: -80px;
    width: 100%;
  }
}

