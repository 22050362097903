.moreService{
    .adm-nav-bar{
        background-color: #EAAB00;
        height: 60px;
        color: #003946;
        .adm-nav-bar-left{
            span{
                font-size: 17px;
                font-weight: 400;
            }
        }
        .adm-nav-bar-title{
            font-size: 20px;
            font-weight: 500;
        }
    }
    .moreSerItem{
        background-color: #ffffff;
        margin-top: 10px;
        .moreSerTitle{
            font-size: 20px;
            font-weight:500;
            color: #003946;
            padding-top: 10px;
            padding-left: 20px;
        }
        
    }
}