.LatestNews{
    .latestNewsList{
        margin-top: 10px;
    }
    padding: 20px 12px;
    .title{
        font-size: 20px;
        color: #333333;
        font-weight: 500;
        // margin-left: 12px;
    }
    .itemTitle{
        font-weight: 700;
        font-size: 14px;
        color: #003946;
    }
}