

.deskBanner{
    .imgItem{
        color: #ffffff;
        display: flex;
        // justify-content: center;
        // align-items: center;
        font-size: 48px;
        user-select: none;
      }
    
}