.Notification{
    position: relative;
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 100 / 16;
        img{
            margin-left: 10px;
        }
        span{
            margin-left: 5px;
            width: 100%;
            line-height: 28px;
            font-size: 20px;
            color: #202223;
        }
    }
    
}
