.Browser{
    background-color: #ffffff;
    padding:0 40px;
    // width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: #003946;
    font-weight: 700;
    // padding-top: 40px;
    >:first-child {
        margin-top: 40px;
    }
    > .step{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        >*{
            margin-bottom: 10px;
        }
        .instruct{
            font-size: 16px;
        }
        .imgGroup{
            img{
                margin: 0 5px;
            }
        }
        .underline{
            text-decoration: underline;
        }
    }
}