
.ensure{
  padding: 20px 0;
  .title{
    font-size: 20px;
    color: #333333;
    font-weight: 500;
    margin-left: 12px;
  }
  .itemTitle{
    margin-top: 10px;
    color: #013946;
    font-size: 14px;
    font-weight: 500;
  }
  .itemDescription{
    margin-top: 10px;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
  }
  .Insurance {
    --title-font-size:14px;
    --active-title-color: #024C6C;
    --active-line-color:#EAAB00;
  }
}
