/* 禁用iPhone中Safari的字号自动调整 */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    /* 解决IOS默认滑动很卡的情况 */
    -webkit-overflow-scrolling : touch;
}
 
/* 禁止缩放表单 */
input[type="submit"], input[type="reset"], input[type="button"], input {
    resize: none;
    border: none;
}
 
/* 取消链接高亮  */
body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
 
/* 设置HTML5元素为块 */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
 
/* 图片自适应 */
/*img {
    width: 100%;
    height: auto;
    width: auto\9; 
    display: block;
    -ms-interpolation-mode: bicubic;
}
*/
/* ie8 */
/*为了照顾ie图片缩放失真*/

 
/* 初始化 */
body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, 
form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, 
menu, nav, section {
    margin: 0;
    padding: 0;
}
body {
    font: 12px/1.5 'Microsoft YaHei','宋体', Tahoma, Arial, sans-serif;
    color: #000000;
    background-color: #F7F7F7;
}
em, i {
    font-style: normal;
}
ul,li{
    list-style-type: none;
}
strong {
    font-weight: normal;
}
table{
    border-collapse:collapse;
    border-spacing:0
}
textarea {
    resize:none; /*禁用了文本的拖拉，尤其在谷歌下*/
} 
p {
    word-wrap:break-word; /* 不够的单词自动换行 而不会被截掉 */
}
.loading{
    font-size: 18px;
}
.clearfix:after {
    content: "";
    display: block;
    visibility: hidden;
    height: 0;
    clear: both;
}
.clearfix {
    zoom: 1;
}
a {
    text-decoration: none;
    color: #fff;
    font-family: 'Microsoft YaHei', Tahoma, Arial, sans-serif;
}
a:hover {
    text-decoration: none;
}
ul, ol {
    list-style: none;
}
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-family: 'Microsoft YaHei';
}
img {
    border: none;
}
input{
    font-family: 'Microsoft YaHei';
}

/*单行溢出*/
.one-txt-cut{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/*多行溢出 手机端使用*/
.txt-cut{
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
}
/* 移动端点击a链接出现蓝色背景问题解决 */
a:link,a:active,a:visited,a:hover {
    background: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}
.overflow {overflow:hidden; }
.w50{
    width: 50%;
}
.w25{
    width: 25%;
}
.w20{
    width: 20%;
}
.w33{
    width: 33.333333%;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.db{
    display: block !important;
}
.dn{
    display: none;
}
 