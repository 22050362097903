.learnMore{
    position: relative;
    .content{
        background-color: #ffffff;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 100 / 20;
        img{
            margin-left: 20px;
            margin-right: 10px;
        }
        .textContent{
            margin-right: auto;
            font-size: 12px;
            .bold{
                font-weight: 700;
                color:#003946;
                font-size: 16px;
            }
        }
        .btn{
            button{
                width: 100px;
                height: 30px;
                background-color: #004570;
                margin-right: 20px;
                color: #ffffff;
                font-size: 12px;
            }
        }
    }
}
