.HomePage{
    height: 100%;
    width: 100%;
    top: 0;
    .MainContent{
        // padding-top: 50px;
        padding-bottom: 50px;
    }
    .Footer{
        background-color: #fff;
        position: fixed;
        width: 100%;
        bottom: 0;
        .adm-tab-bar-item-active{
            color: #EAAB00;
        }
        .adm-tab-bar-item{
            width: 25%;
            padding: 8px 16px;
        }
    }
}
.HomePage::-webkit-scrollbar {
    display: none; /* 隐藏滚动条（适用于Chrome和Safari） */
}
.HomePage {
    -ms-overflow-style: none; /* 隐藏IE和Edge中的滚动条 */
    scrollbar-width: none; /* 隐藏Firefox中的滚动条 */
}